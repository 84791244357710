.main-container {
  margin-top: 38vh;
  height: 62vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
}

.main-container > div {
  flex: 1;
}

.intro,
.about-me,
.resume,
.projects,
.contact {
  scroll-snap-align: start;
  height: 62vh;
}

body {
  font-family: 'Roboto', sans-serif;
}

.nav {
  font-family: 'Roboto', sans-serif;
}

.custom-height{
  height: 62vh;
}

#icon {
  text-align: center;
}

.intro.section .badge.bg-danger {
  background-color: #808080 !important;
}

.list-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 32vh;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 5%;
}

.nav {
  display: flex;
  justify-content: space-evenly;
  width: 60%;
}

.nav-item {
  text-align: center;
}

.nav-link {
  cursor: pointer;
  text-decoration: none;
  color: #000;
}

.nav-link.active {
  border-bottom: 1vh solid #000;
}

.projects .table th,
.projects .table td {
  width: auto;
  font-size: 1.5vh;
}


.resume .table th,
.resume .table td {
  width: auto;
  font-size: 1.5vh;
}

.about-me .container {
  font-size: 1.5vh;
}