.chart-container {
    position: fixed;
    top: 0;
    /* left: 0; */
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 25%;
    z-index: 1000;
    justify-content: center;
  }
  
  .chart {
    position: absolute;
    top: 8vh;
    left: 0;
    width: 100%;
    height: 100%;
    
  }

.chart-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
  }
  
  .header {
    margin-left: 2vh;
    color: #0d6efd;
  }
  
  .price-trend-container {
    display: flex;
    gap: 1.5vh;
    margin-left: auto;
    margin-right: 2vh;
  }

  .green {
    color: green;
  }
  
  .grey {
    color: #808080;
  }

  .red {
    color: red;
  }



  